import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

// export const API_URL = 'http://localhost:8087/api'
// export const DOWNLOAD_URL = 'http://localhost:8087/'
// export const DOWNLOAD_URL = 'http://localhost:8087/api/user/download/'
export const API_URL = 'https://nanakfinserv.com/api'
// export const DOWNLOAD_URL = 'https://nanakfinserv.com/'
export const DOWNLOAD_URL = 'https://nanakfinserv.com/api/user/download/'


const login = async (mobileNumber, addToast) => {
  try {
    const response = await axios.post(`${API_URL}/user/login`, { mobileNumber },{ withCredentials: true });

    if (!response.data) {
      throw new Error('Invalid credentials');
    }

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      console.log(response.data, 'sds')
      if (response.data) {
        const token = response.data.token;
        console.log(token);
        setToken(token);
        setUser(response.data.user);
        setCategory(response.data.user?.category);
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const loginVerfiy = async (mobileNumber) => {
  try {
    const response = await axios.post(`${API_URL}/user/verfiy`, { mobileNumber });
    console.log(response);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return true;
      } else {
        return false;
      }
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getRoles = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.get(`${API_URL}/user/role/list`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllUnitCatergory = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.get(`${API_URL}/user/data/unitCategory`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};


export const getAllConsumers = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    console.log(headers, 'headers')
    const response = await axios.get(`${API_URL}/user/list/consumer`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllVerticle = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/verticle`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllCodes = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/data/code`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllInquieries = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/data/inquiery`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllVerticleUser = async (data) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.post(`${API_URL}/user/list/verticleUser`, data, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addRoleUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/role/add`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Role user add successfully', 'success')
      toast.success('Role user add successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addInquieryUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/inquiery`, data, headers);
    console.log(response);

    if (!response.data) {
      // alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('user form submit successfully', 'success')
      toast.success('user form submit successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addBuilderUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/add/builder`, data, headers);

    if (!response.data) {
      // alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Builder add successfully', 'success')
      toast.success('Builder add successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};


export const addConsumerUser = async (data) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/add/consumer`, data, headers);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Consumer add successfully', 'success')
      toast.success('Consumer add successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addNewCode = async (data) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/code`, data, headers);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Consumer add successfully', 'success')
      toast.success('Consumer add successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addConsumerUnit = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/consumer/add`, data, headers);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Consumer add successfully', 'success')
      toast.success('Consumer add successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addUnitByBuilder = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/add/builderUnit`, data, headers);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Unit builder add successfully', 'success')
      toast.success('Unit builder add successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};


export const updateRoleUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/data/role/update`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Role user update successfully', 'success')
      toast.success('Role user update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateUnitByBuilder = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/data/update/builderUnit`, data, headers);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Unit category builder update successfully', 'success')
      toast.success('Unit category builder update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getConsumerByUnit = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/builder/getunitwithconsumer`, data, headers);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Unit consumer get successfully', 'success')
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};


export const updateBuilderUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/update/builder`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Builder update successfully', 'success')
      toast.success('Builder update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateMediclaimCompany = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/mediclaim/company/update`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Company update successfully', 'success')
      toast.success('Company update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};
export const addMediclaimCompany = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/mediclaim/company/add`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Company update successfully', 'success')
      toast.success('Company update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateMediclaimProduct = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    },
  };

  try {
    const response = await axios.put(
      `${API_URL}/user/mediclaim/product/update/${data.get('mediclaim_company_id')}`,
      data, // FormData object containing text data and files
      headers
    );

    console.log(response);

    if (!response.data) {
      // addToast('Something went wrong', 'error');
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
    } else {
      // addToast('Company updated successfully', 'success');
      toast.success('data update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
  } catch (error) {
    console.error('Update failed:', error);
    errorHandel(error);
    return false; // Update failed
  }
};

export const addMediclaimProduct = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    },
  };

  try {
    const response = await axios.post(
      `${API_URL}/user/mediclaim/product/add/${data.get('mediclaim_company_id')}`,
      data, // FormData object containing text data and files
      headers
    );

    console.log(response);

    if (!response.data) {
      // addToast('Something went wrong', 'error');
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
    } else {
      // addToast('Company added successfully', 'success');
      toast.success('Data added successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
  } catch (error) {
    console.error('Addition failed:', error);
    toast.error('Something went wrong', {
      duration: 3000, // 5 seconds
      style: {
        background: '#333',
        color: '#fff',
      },
    });
    errorHandel(error);
    return false; // Addition failed
  }
};

export const updateConsumerUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/data/update/consumer`, data, headers);


    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Consumer update successfully', 'success')
      toast.success('Consumer update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateLoanConsumerUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/data/add/consumer/loan`, data, headers);


    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Consumer update successfully', 'success')
      toast.success('Consumer update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateConsumerUnit = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/data/consumer/update/${data?.builderConsumerId}`, data, headers);


    if (!response.data) {
      alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      // addToast('Consumer update successfully', 'success')
    toast.success('Consumer update successfully', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addMediclaimUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/mediclaim/user/add`, data, headers);

    if (!response.data) {
      // alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      addToast('mediclaim add successfully', 'success')
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateMediclaimUser = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/mediclaim/user/update/${data?.id}`, data, headers);


    if (!response.data) {
      // alert('error')
      // addToast('something went wrong', 'error')
      toast.error('Something went wrong', {
        duration: 3000, // 5 seconds
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      throw new Error('Invalid credentials');
      // return false;

    } else {
      addToast('mediclaim update successfully', 'success')
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};
export const getRoleUserList = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.get(`${API_URL}/user/list/roleWise`, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.log(error.message)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getUserCountList = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.get(`${API_URL}/user/data/counts`, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.log(error.message)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getLoanAmounFilterDate = async (data) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/data/filter/amount`,data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.log(error.message)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getUnitsByBuilder = async (id) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.get(`${API_URL}/user/data/builder/unit`, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getUnitsByBuilderCategory = async (unitId) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.get(`${API_URL}/user/data/builder/unitCategory/${unitId}`, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getCategoryById = async (user_id) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/list/categoriesById`, { user_id }, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};
export const getAllBuildersList = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/builder/list`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};
export const getAllBuilders = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/builder`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};


export const getAllLoanConsumerDetail = async (data) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.post(`${API_URL}/user/list/loan/detail`,data, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};
export const getAllLoanConsumer = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/loan`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllLoanInterestedConsumer = async (obj) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.post(`${API_URL}/user/list/loanInterested`, obj,headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllMediclaimCompany = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/mediclaim/company`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllMediclaimProduct = async (data) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/mediclaim/product/${data.mediclaim_company_id}`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllLoanNotInterestedConsumer = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/loanNotInterested`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllConfiguration = async (obj) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/data/loan/configuration`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};
export const getAllLoanDisburseConsumer = async (obj) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.post(`${API_URL}/user/list/loanNotDisburse`,obj, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateLoanStatus = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/list/loanUpdateStatus`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      addToast('something went wrong', 'error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      addToast('Loan status update successfully', 'success')
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const updateLoanWorkingStatus = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.put(`${API_URL}/user/list/loanUpdateWorkingStatus`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      addToast('something went wrong', 'error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      addToast('Loan working status update successfully', 'success')
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllMedicalimConsumer = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/mediclaim`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllMedicalimConsumerData = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/mediclaim/user/list`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllLifeInsConsumer = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/lifeIns`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const getAllVehicleInsConsumer = async () => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {

    const response = await axios.get(`${API_URL}/user/list/vehicleIns`, headers);

    if (!response.data) {
      alert('error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      if (response.data && response.data?.status) {
        return response.data;
      } else {
        return [];
      }
    }

    // return re; // Login successful
  } catch (error) {
    console.log(error.response?.status)
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

export const addUpdateLoanConfiguration = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/loan/configuration/add`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      addToast('something went wrong', 'error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      addToast('Loan disburse pdf added successfully', 'success')
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};
export const addUpdateLoanDisburse = async (data, addToast) => {
  const headers = {
    headers: {
      'token': Cookies.get('token'),
    }
  }
  try {
    const response = await axios.post(`${API_URL}/user/loan/disburse/add`, data, headers);
    console.log(response);

    if (!response.data) {
      alert('error')
      addToast('something went wrong', 'error')
      throw new Error('Invalid credentials');
      // return false;

    } else {
      addToast('Loan disburse pdf added successfully', 'success')
      return response.data;
    }
    // return response; // Login successful
  } catch (error) {
    console.error('Login failed:', error);
    errorHandel(error)
    return false; // Login failed
  }
};

const setToken = (token) => {
  // Set token in cookies
  Cookies.set('token', token, { expires: 7 }); // token expires in 7 days
};

const setUser = (user) => {
  // Set token in cookies
  Cookies.set('user', JSON.stringify(user), { expires: 7 }); // token expires in 7 days
};

const setCategory = (category) => {
  // Set token in cookies
  if (category && category.length) {
    let categoryData = category.map((item) => item['category.category_id'])
    Cookies.set('category', categoryData, { expires: 7 }); // token expires in 7 days
  } else {
    Cookies.set('category', [], { expires: 7 }); // token expires in 7 days
  }
  console.log(category)

};

const errorHandel = (error) => {
  toast.error('Something went wrong', {
    duration: 3000, // 5 seconds
    style: {
      background: '#333',
      color: '#fff',
    },
  });
  if (error?.response?.status == 401) {
    logout()
  }
}
const getToken = () => {
  // Get token from cookies
  return Cookies.get('token');
};

const logout = () => {
  // Clear token from cookies
  Cookies.remove('token');
  Cookies.remove('user');
  Cookies.remove('category');
  window.location.reload();
};

export { login, getToken, logout };
