import React, { useEffect, useState } from 'react';
import './popup-u.css';
import { addMediclaimUser, DOWNLOAD_URL, getAllMediclaimCompany, getAllMediclaimProduct, updateMediclaimUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const MediclaimPopup = ({ isOpen, onClose, fetchApi, initialData, view }) => {
  console.log(view)
  const addToast = useToaster()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [companyData, setCompanyData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productType, setProductType] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [formState, setFormState] = useState({
    formData: {
      Name: '',
      MobileNumber: '',
      Email: '',
      RadioButton: '', // Fresh, Renew, Portability
      policyRadio: '',
      DateOfBirth: '',
      Age: '',
      Gender: '',
      RelationshipWithPolicyHolder: '',
      SumInsured: '',
      AdditionalSumInsured: '',
      NoClaimBonus: '',
      PreExistingIllness: '',
      AddOnCover: '',
      ExpiryDate: '',
      ClaimInExpiringPolicy: '',
      NomineeName: '',
      CustomerID: '',
      PolicyPlanType: '',

      // Running Policy Data
      runningPolicy: {
        Zone: '',
        PolicyNumber: '',
        PolicyTenure: '',
        PremiumAmount: '',
        NomineeName: '',
        NomineeAge: '',
        NomineeDob: '',
        PolicyTo: '',
        PolicyFrom: '',
        PDFfile: '',
        ClaimExpireInPolicy: '',
      },

      // Previous Policy Data (For Renewals and Portability)
      previousPolicy: {
        PolicyTo: '',
        PolicyFrom: '',
        Zone: '',
        RenewDate: '',
        PolicyNumber: '',
        PolicyTenure: '',
        PremiumAmount: '',
        NomineeName: '',
        PDFfile: '',
        ClaimExpireInPolicy: '',
      },

      // Last Policy Data (Historical Reference)
      AgentName: '',
      AgentCode: '',
      AgentContactNumber: '',
      ProductName: '',
      CompanyName: '',

    },
    familyMembers: [
      {
        DateOfBirth: '',
        Age: '',
        Gender: '',
        RelationshipWithPolicyHolder: '',
      },
    ],
    errors: {},
  });


  useEffect(() => {
    if (initialData && initialData.id) {
      const familyMembers = initialData.familymembers?.map(item => ({
        DateOfBirth: item.DateOfBirth ? item.DateOfBirth.slice(0, 10) : "",
        Age: item.Age || "",
        Gender: item.Gender || "",
        RelationshipWithPolicyHolder: item.RelationshipWithPolicyHolder || "",
      })) || [];

      const {
        user = {},
        medicliam_type,
        medicliam_policy_type,
        dob,
        age,
        gender,
        relationshipWithPolicyHolder,
        sumInsured,
        additionalSumInsured,
        noClaimBonus,
        preExistingIllness,
        addOnCover,
        policyPlanType,
        policyNumber,
        policyTenure,
        expiryDate,
        claimInExpiringPolicy,
        nomineeName,
        customerID,
        runningPolicyZone,
        runningPolicyNumber,
        runningPolicyTenure,
        runningPremiumAmount,
        runningNomineeName,
        runningPolicyFrom,
        runningPolicyTo,
        runningPDFfile,
        claimExpireInPolicy,
        previousPolicyZone,
        previousPolicyNumber,
        previousPolicyFrom,
        previousPolicyTo,
        previousPolicyTenure,
        previousPremiumAmount,
        previousNomineeName,
        previousPolicyRenewDate,
        previousPDFfile,
        previousClaimExpireInPolicy,
        lastPolicyNumber,
        policyIssuedDate,
        renewDate,
        agentName,
        agentCode,
        agentContactNumber,
        mediclaim_product_id,
        mediclaim_company_id
      } = initialData;

      setFormState({
        formData: {
          Name: user.username || '',
          MobileNumber: user.mobileNumber || '',
          Email: user.email || '',
          RadioButton: medicliam_type || '',
          PolicyType: medicliam_policy_type || '',
          DateOfBirth: dob ? dob.slice(0, 10) : '',
          Age: age || '',
          Gender: gender || '',
          RelationshipWithPolicyHolder: relationshipWithPolicyHolder || '',
          SumInsured: sumInsured || '',
          AdditionalSumInsured: additionalSumInsured || '',
          NoClaimBonus: noClaimBonus || '',
          PreExistingIllness: preExistingIllness || '',
          AddOnCover: addOnCover || '',
          PolicyNumber: policyNumber || '',
          PolicyTenure: policyTenure || '',
          ExpiryDate: expiryDate ? expiryDate.slice(0, 10) : '',
          ClaimInExpiringPolicy: claimInExpiringPolicy || '',
          NomineeName: nomineeName || '',
          CustomerID: customerID || '',
          PolicyPlanType: policyPlanType || '',

          // Running Policy
          RunningZone: runningPolicyZone || '',
          RunningPolicyNumber: runningPolicyNumber || '',
          RunningPolicyTenure: runningPolicyTenure || '',
          RunningPremiumAmount: runningPremiumAmount || '',
          RunningNomineeName: runningNomineeName || '',
          RunningPolicyFrom: runningPolicyFrom ? runningPolicyFrom.slice(0, 10) : '',
          RunningPolicyTo: runningPolicyTo ? runningPolicyTo.slice(0, 10) : '',
          RunningPDFfile: runningPDFfile || '',
          ClaimExpireInPolicy: claimExpireInPolicy || '',

          // Previous Policy
          PreviousPolicyZone: previousPolicyZone || '',
          PreviousPolicyNumber: previousPolicyNumber || '',
          PreviousPolicyFrom: previousPolicyFrom ? previousPolicyFrom.slice(0, 10) : '',
          PreviousPolicyTo: previousPolicyTo ? previousPolicyTo.slice(0, 10) : '',
          PreviousPolicyTenure: previousPolicyTenure || '',
          PreviousPremiumAmount: previousPremiumAmount || '',
          PreviousNomineeName: previousNomineeName || '',
          PreviousPolicyRenewDate: previousPolicyRenewDate ? previousPolicyRenewDate.slice(0, 10) : '',
          PreviousPDFfile: previousPDFfile || '',
          PreviousClaimExpireInPolicy: previousClaimExpireInPolicy || '',

          // Last Policy
          LastPolicyNumber: lastPolicyNumber || '',
          PolicyIssuedDate: policyIssuedDate ? policyIssuedDate.slice(0, 10) : '',
          RenewDate: renewDate ? renewDate.slice(0, 10) : '',
          AgentName: agentName || '',
          AgentCode: agentCode || '',
          AgentContactNumber: agentContactNumber || '',
          ProductName: mediclaim_product_id || '',
          CompanyName: mediclaim_company_id || ''
        },
        familyMembers
      })
      setCompanyType(initialData.mediclaim_company_id || '')
      if (initialData.mediclaim_company_id) {
        getProductData(initialData.mediclaim_company_id)
        setProductType(initialData.mediclaim_product_id)
      }
    }
  }, [initialData]);

  useEffect(() => {
    getCompanyData()
  }, []);

  const validateForm = () => {
    const errors = {};
    const { formData, familyMembers } = formState;

    // Helper function for common validations
    const isValidNumber = (value) => !isNaN(value) && value > 0;
    const isValidDate = (date) => date && !isNaN(new Date(date).getTime());

    // Step 1: Basic Information Validation
    if (currentStep === 1) {
      if (!formData.Name) errors.Name = 'Name is required';
      if (!formData.Email || !/\S+@\S+\.\S+/.test(formData.Email)) errors.Email = 'Valid Email is required';
      if (!formData.MobileNumber || formData.MobileNumber.length < 10) errors.MobileNumber = 'Valid Mobile Number is required';
      if (!companyType) errors.CompanyName = 'Company Name is required';
      if (!formData.RadioButton) errors.RadioButton = 'Please select Family or Individual';
    }

    // Step 2: Policyholder & Family Validation
    else if (currentStep === 2) {
      if (!formData.RadioButton) errors.RadioButton = 'Please select Family or Individual';

      // Common Validations for Both Individual & Family
      if (!formData.DateOfBirth) errors.DateOfBirth = 'Date of Birth is required';
      if (!formData.Gender) errors.Gender = 'Gender is required';
      if (!formData.RelationshipWithPolicyHolder) errors.RelationshipWithPolicyHolder = 'Relationship with Policy Holder is required';
      if (!formData.PreExistingIllness) errors.PreExistingIllness = 'Pre-existing Illness is required';
      if (!isValidNumber(formData.SumInsured)) errors.SumInsured = 'Valid Sum Insured is required';
      if (!isValidNumber(formData.NoClaimBonus)) errors.NoClaimBonus = 'Valid No Claim Bonus is required';

      // Additional validation for Family members
      if (formData.RadioButton === 'Family') {
        familyMembers.forEach((member, index) => {
          if (!member.DateOfBirth) errors[`member${index}_DateOfBirth`] = `Date of Birth is required for family member ${index + 1}`;
          if (!member.Gender) errors[`member${index}_Gender`] = `Gender is required for family member ${index + 1}`;
          if (!member.RelationshipWithPolicyHolder) errors[`member${index}_RelationshipWithPolicyHolder`] = `Relationship with Policy Holder is required for family member ${index + 1}`;
        });
      }
    }

    // Step 3: Policy Dates & Insurance Period
    else if (currentStep === 3) {
      if (!isValidDate(formData.poifrom)) errors.poifrom = 'Period of Insurance "From" date is required';
      if (!isValidDate(formData.poito)) errors.poito = 'Period of Insurance "To" date is required';

      // Ensure "To" date is after "From" date
      if (isValidDate(formData.poifrom) && isValidDate(formData.poito) && new Date(formData.poito) <= new Date(formData.poifrom)) {
        errors.poito = 'The "To" date must be later than the "From" date';
      }

      if (!isValidNumber(formData.AdditionalSumInsured)) errors.AdditionalSumInsured = 'Valid Additional Sum Insured is required';
      if (!isValidDate(formData.PolicyIssuedDate)) errors.PolicyIssuedDate = 'Policy Issued Date is required';

      // Ensure "Policy Issued Date" is before or equal to "From" date
      if (isValidDate(formData.PolicyIssuedDate) && isValidDate(formData.poifrom) && new Date(formData.PolicyIssuedDate) > new Date(formData.poifrom)) {
        errors.PolicyIssuedDate = 'Policy Issued Date cannot be after the "Period of Insurance From" date';
      }

      if (!formData.PolicyPlanType) errors.PolicyPlanType = 'Policy Plan Type is required';
    }

    // Step 4: Policy Details Validation
    else if (currentStep === 4) {
      if (!formData.PolicyNumber) errors.PolicyNumber = 'Policy Number is required';
      if (!formData.Zone) errors.Zone = 'Zone is required';
      if (!isValidNumber(formData.PolicyTenure)) errors.PolicyTenure = 'Valid Policy Tenure is required';
      if (!isValidNumber(formData.PremiumAmount)) errors.PremiumAmount = 'Valid Premium Amount is required';
      if (!formData.ClaimInExpiringPolicy) errors.ClaimInExpiringPolicy = 'Claim in Expiring Policy is required';
      if (!formData.PreviousPolicyNumber) errors.PreviousPolicyNumber = 'Previous Policy Number is required';
      if (!formData.NomineeName) errors.NomineeName = 'Nominee Name is required';
      if (!productType) errors.ProductName = 'Product Name is required';
      if (!formData.CustomerID) errors.CustomerID = 'Customer ID is required';
      if (!isValidDate(formData.RenewDate)) errors.RenewDate = 'Valid Renew Date is required';
    }

    // Step 5: Agent Information Validation
    else if (currentStep === 5) {
      if (!formData.AgentName) errors.AgentName = 'Agent Name is required';
      if (!formData.AgentContactNumber || formData.AgentContactNumber.length < 10) errors.AgentContactNumber = 'Valid Agent Contact Number is required';
      if (!formData.AgentCode) errors.AgentCode = 'Agent Code is required';
    }

    // Update errors in the state
    setFormState((prevState) => ({
      ...prevState,
      errors,
    }));

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };




  const getProductData = async (id) => {
    let obj = {
      mediclaim_company_id: id
    }
    const roleData = await getAllMediclaimProduct(obj);
    if (roleData?.data && roleData?.data?.length) {
      setProductData(roleData?.data)
    } else {
      setProductData([])
    }
  }

  const redirectpageproduct = (pdfPath) => {
    // const file_path = `${DOWNLOAD_URL}public${pdfPath}`;
    const file_path = DOWNLOAD_URL + pdfPath;
    console.log(file_path, "file");

    // Create a temporary <a> element
    const a = document.createElement("a");
    a.href = file_path;
    a.target = "_blank"; // Open in a new tab
    a.download = file_path; // Extract filename from path

    // Trigger the click programmatically
    document.body.appendChild(a);
    a.click();

    // Remove the element after triggering
    document.body.removeChild(a);
  };
  const getCompanyData = async () => {
    const roleData = await getAllMediclaimCompany();
    if (roleData?.data && roleData?.data?.length)
      setCompanyData(roleData?.data)
  }

  const handleNext = () => {
    if (view) {
      if (currentStep < 5) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      // if (validateForm()) {
      if (currentStep < 5) {
        setCurrentStep(currentStep + 1);
      }
      // }
    }

  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent form from submitting if already submitting

    // Only submit when we're on Step 5
    if (currentStep === 5) {
      if (validateForm()) {
        setIsSubmitting(true); // Set flag to true to prevent multiple submissions

        console.log('Form Submitted', formState.formData);

        // Validate mobile number format
        const regex = /^\d{10}$/;
        if (!regex.test(formState?.formData?.MobileNumber)) {
          alert('Mobile number is invalid');
          setIsSubmitting(false); // Reset the flag
          return;
        }

        // Prepare payload with form data and family members
        const payload = {
          ...formState.formData,
          familyMembers: formState.familyMembers,
          ProductName: productType,
          CompanyName: companyType,
        };

        console.log('Payload:', payload);

        // try {
        //   let response;
        //   if (initialData && initialData.id) {
        //     // Update existing record
        //     payload.user_id = initialData.user_id;
        //     payload.id = initialData.id;
        //     response = await updateMediclaimUser(payload, addToast);
        //   } else {
        //     // Add new record
        //     response = await addMediclaimUser(payload, addToast);
        //   }

        //   if (response.status) {
        //     // Success logic
        //     fetchApi();
        //     onClose();
        //   }
        // } catch (error) {
        //   console.error('Error during submission:', error);
        // } finally {
        //   setIsSubmitting(false); // Reset the flag after submission attempt
        // }
      }
    }
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        DateOfBirth: dateOfBirth,
        Age: age
      }
    }));
  };

  const handleNomineeDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        RunningNomineeDob: dateOfBirth,
        RunningNomineeAge: age
      }
    }));
  };

  const handleFamilyMemberChange = (index, field, value) => {
    const updatedFamilyMembers = [...formState.familyMembers];

    // Ensure the family member at the given index exists or initialize it
    if (!updatedFamilyMembers[index]) {
      updatedFamilyMembers[index] = {
        DateOfBirth: '',
        Age: '', // Ensure Age is part of the new family member
        Gender: '',
        RelationshipWithPolicyHolder: ''
      };
    }

    // Handle 'DateOfBirth' specifically because we need to calculate 'Age'
    if (field === 'DateOfBirth') {
      const age = calculateAge(value); // Calculate the age based on DateOfBirth

      // If age is valid, update the 'Age' field
      if (age !== null) {
        updatedFamilyMembers[index][field] = value; // Update the DateOfBirth
        updatedFamilyMembers[index]['Age'] = age; // Update the Age
      }
    } else {
      // For all other fields, just update the value directly
      updatedFamilyMembers[index][field] = value;
    }

    // Update the formState with the modified family members
    setFormState(prevState => ({
      ...prevState,
      familyMembers: updatedFamilyMembers
    }));
  };
  console.log(formState.errors)


  const addFamilyMember = () => {
    setFormState(prevState => ({
      ...prevState,
      familyMembers: [
        ...prevState.familyMembers,
        { DateOfBirth: '', Age: '', Gender: '', RelationshipWithPolicyHolder: '' }
      ]
    }));
  };

  const removeFamilyMember = (index) => {
    const updatedFamilyMembers = [...formState.familyMembers];
    updatedFamilyMembers.splice(index, 1);
    setFormState(prevState => ({ ...prevState, familyMembers: updatedFamilyMembers }));
  };

  const onChangeHandle = (e) => {
    setProductType('');
    setFormState(prevState => ({ ...prevState, CompanyName: e.target.value }));
    getProductData(e.target.value)

  }

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='popup-header d-flex justify-content-between align-items-center'>
          <h2>{initialData ? 'Edit Mediclaim' : 'Add Mediclaim'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Consumer Details</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="Name"
                    required
                    className='form-control'
                    placeholder="Name"
                    value={formState.formData.Name}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Name: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Name && <p className="text-danger">{formState.errors.Name}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="email"
                    name="Email"
                    required
                    className='form-control'
                    placeholder="Email"
                    value={formState.formData.Email}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Email: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Email && <p className="text-danger">{formState.errors.Email}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="MobileNumber"
                    required
                    className='form-control'
                    placeholder="Mobile Number"
                    value={formState.formData.MobileNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        MobileNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.MobileNumber && <p className="text-danger">{formState.errors.MobileNumber}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <select
                    name="CompanyName"
                    required
                    value={companyType} className='form-select' onChange={(e) => { setCompanyType(e.target.value); onChangeHandle(e) }}>
                    <option value="" disabled hidden>Select Company</option>
                    {companyData && companyData.map((item) => (
                      <option key={item.mediclaim_company_id} value={item.mediclaim_company_id}>
                        {item.mediclaim_company_name}
                      </option>
                    ))}
                  </select>
                  {formState?.errors?.CompanyName && <p className="text-danger">{formState.errors.CompanyName}</p>}
                </div>
                <div className='col-md-6 mb-4 d-grid'>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="RadioButton"
                      required
                      className='me-2'
                      value="Family"
                      checked={formState.formData.RadioButton === 'Family'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RadioButton: e.target.value
                        }
                      }))}
                    />
                    Family
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="RadioButton"
                      className='me-2'
                      value="Individual"
                      checked={formState.formData.RadioButton === 'Individual'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RadioButton: e.target.value
                        }
                      }))}
                    />
                    Individual
                  </label>
                  {formState?.errors?.RadioButton && <p className="text-danger">{formState.errors.RadioButton}</p>}
                </div>
                <div className='col-md-6 mb-4 d-grid'>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="policyRadio"
                      required
                      className='me-2'
                      value="Fresh"
                      checked={formState.formData.policyRadio === 'Fresh'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          policyRadio: e.target.value
                        }
                      }))}
                    />
                    Fresh
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="policyRadio"
                      className='me-2'
                      value="Renewal"
                      checked={formState.formData.policyRadio === 'Renewal'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          policyRadio: e.target.value
                        }
                      }))}
                    />
                    Renewal
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="policyRadio"
                      className='me-2'
                      value="Portability"
                      checked={formState.formData.policyRadio === 'Portability'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          policyRadio: e.target.value
                        }
                      }))}
                    />
                    Portability
                  </label>
                  {formState?.errors?.policyRadio && <p className="text-danger">{formState.errors.policyRadio}</p>}
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Running Policy Details</h3>
              </div>
              {formState.formData.RadioButton === 'Family' && (
                <>
                  <div>
                    <button type="button" className='btn btn-blue mb-2 ' onClick={addFamilyMember}>Add Family Member</button>
                  </div>
                  {(formState.formData.RadioButton === 'Family') && (
                    <>
                      <div className='row mt-2'>
                        <div className='col-md-6 mb-4'>
                          <label>Date of birth</label>
                          <input
                            type="date"
                            name="DateOfBirth"
                            className='form-control'
                            placeholder="Date of Birth"
                            value={formState?.formData?.DateOfBirth}
                            onChange={handleDateChange}
                          />
                          {formState?.errors?.DateOfBirth && (
                            <div className="text-danger">{formState?.errors?.DateOfBirth}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <label>Age</label>
                          <input
                            type="text"
                            name="Age"
                            className='form-control'
                            placeholder="Age"
                            value={formState?.formData?.Age}
                            readOnly
                          />
                          {formState?.errors?.Age && <div className="text-danger">{formState?.errors?.Age}</div>}
                        </div>
                        <div className='col-md-12 mb-4'>
                          <label>Gender</label>
                          <div className='d-flex'>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Male"
                                checked={formState.formData.Gender === 'Male'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Male
                            </label>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Female"
                                checked={formState.formData.Gender === 'Female'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Female
                            </label>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Other"
                                checked={formState.formData.Gender === 'Other'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Other
                            </label>
                          </div>
                          {formState?.errors?.Gender && (
                            <div className="text-danger">{formState.errors.Gender}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <input
                            type="text"
                            className='form-control'
                            name="RelationshipWithPolicyHolder"
                            placeholder="Relationship with Policy Holder"
                            value={formState.formData.RelationshipWithPolicyHolder}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                RelationshipWithPolicyHolder: e.target.value
                              }
                            }))}
                          />
                          {formState?.errors?.RelationshipWithPolicyHolder && (
                            <div className="text-danger">{formState.errors.RelationshipWithPolicyHolder}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <input
                            type="text"
                            name="PreExistingIllness"
                            className='form-control'
                            placeholder="Pre Existing Illness"
                            value={formState.formData.PreExistingIllness}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                PreExistingIllness: e.target.value
                              }
                            }))}
                          />
                          {formState?.errors?.PreExistingIllness && (
                            <div className="text-danger">{formState.errors.PreExistingIllness}</div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {formState.formData.RadioButton === 'Family' && (
                    <>
                      {formState.familyMembers && formState.familyMembers.map((member, index) => (
                        <div key={index} className="family-member-section mt-3">
                          <h5>Family Member {index + 1}</h5>
                          <div className='row'>
                            <div className='col-md-6 mb-3'>
                              <label>Date of Birth</label>
                              <input
                                type="date"
                                className='form-control'
                                value={member.DateOfBirth}
                                onChange={(e) => handleFamilyMemberChange(index, 'DateOfBirth', e.target.value)}
                              />
                              {formState?.errors && formState?.errors[`member${index}_DateOfBirth`] && (
                                <div className="text-danger">{formState?.errors[`member${index}_DateOfBirth`]}</div>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label>Age</label>
                              <input
                                type="text"
                                value={member.Age}
                                disabled
                                className="form-control"
                              />
                              {formState?.errors && formState?.errors[`member${index}_Age`] && (
                                <div className="text-danger">{formState.errors[`member${index}_Age`]}</div>
                              )}
                            </div>
                            <div className='col-md-6 mb-3'>
                              <label>Gender</label>
                              <div className='d-flex'>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Male"
                                    checked={member.Gender === 'Male'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Male
                                </label>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Female"
                                    checked={member.Gender === 'Female'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Female
                                </label>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Other"
                                    checked={member.Gender === 'Other'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Other
                                </label>
                              </div>
                              {formState?.errors && formState?.errors[`member${index}_Gender`] && (
                                <div className="text-danger">{formState.errors[`member${index}_Gender`]}</div>
                              )}
                            </div>
                            <div className='col-md-6 mb-3'>
                              <label>Relationship with Policy Holder</label>
                              <input
                                type="text"
                                className='form-control'
                                value={member.RelationshipWithPolicyHolder}
                                onChange={(e) => handleFamilyMemberChange(index, 'RelationshipWithPolicyHolder', e.target.value)}
                              />
                              {formState?.errors && formState?.errors[`member${index}_RelationshipWithPolicyHolder`] && (
                                <div className="text-danger">{formState.errors[`member${index}_RelationshipWithPolicyHolder`]}</div>
                              )}
                            </div>
                            <div className='col-md-12'>
                              <button
                                type="button"
                                className='btn btn-danger'
                                onClick={() => removeFamilyMember(index)}
                              >
                                Remove Family Member
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  <div className='row mt-2'>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="SumInsured"
                        className='form-control'
                        placeholder="Sum Insured"
                        value={formState.formData.SumInsured}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            SumInsured: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.SumInsured && (
                        <div className="text-danger">{formState.errors.SumInsured}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="NoClaimBonus"
                        className='form-control'
                        placeholder="No Claim Bonus"
                        value={formState.formData.NoClaimBonus}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            NoClaimBonus: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.NoClaimBonus && (
                        <div className="text-danger">{formState.errors.NoClaimBonus}</div>
                      )}
                    </div>
                  </div>
                </>


              )}

              {(formState.formData.RadioButton === 'Individual') && (
                <>
                  <div className='row mt-2'>
                    <div className='col-md-6 mb-4'>
                      <label>Date of birth</label>
                      <input
                        type="date"
                        name="DateOfBirth"
                        className='form-control'
                        placeholder="Date of Birth"
                        value={formState.formData.DateOfBirth}
                        onChange={handleDateChange}
                      />
                      {formState.errors.DateOfBirth && (
                        <div className="text-danger">{formState.errors.DateOfBirth}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>Age</label>
                      <input
                        type="text"
                        name="Age"
                        className='form-control'
                        placeholder="Age"
                        value={formState.formData.Age}
                        readOnly
                      />
                      {formState.errors.Age && <div className="text-danger">{formState.errors.Age}</div>}
                    </div>
                    <div className='col-md-12 mb-4'>
                      <label>Gender</label>
                      <div className='d-flex'>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Male"
                            checked={formState.formData.Gender === 'Male'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Male
                        </label>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Female"
                            checked={formState.formData.Gender === 'Female'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Female
                        </label>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Other"
                            checked={formState.formData.Gender === 'Other'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Other
                        </label>
                      </div>
                      {formState?.errors?.Gender && (
                        <div className="text-danger">{formState.errors.Gender}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="text"
                        className='form-control'
                        name="RelationshipWithPolicyHolder"
                        placeholder="Relationship with Policy Holder"
                        value={formState.formData.RelationshipWithPolicyHolder}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            RelationshipWithPolicyHolder: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.RelationshipWithPolicyHolder && (
                        <div className="text-danger">{formState.errors.RelationshipWithPolicyHolder}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="text"
                        name="PreExistingIllness"
                        className='form-control'
                        placeholder="Pre Existing Illness"
                        value={formState.formData.PreExistingIllness}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            PreExistingIllness: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.PreExistingIllness && (
                        <div className="text-danger">{formState.errors.PreExistingIllness}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="SumInsured"
                        className='form-control'
                        placeholder="Sum Insured"
                        value={formState.formData.SumInsured}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            SumInsured: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.SumInsured && (
                        <div className="text-danger">{formState.errors.SumInsured}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="NoClaimBonus"
                        className='form-control'
                        placeholder="No Claim Bonus"
                        value={formState.formData.NoClaimBonus}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            NoClaimBonus: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.NoClaimBonus && (
                        <div className="text-danger">{formState.errors.NoClaimBonus}</div>
                      )}

                    </div>

                  </div>
                </>
              )}

            </>
          )}
          {currentStep === 3 && (
            <>
              <div className="mediclaim-h1">
                <h3>Running Policy Details</h3>
              </div>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label>Period of Insurance</label>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Number</label>
                  <input
                    type="text"
                    name="RunningPolicyNumber"
                    className="form-control"
                    placeholder="Policy Number"
                    value={formState?.formData?.runningPolicy?.PolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PolicyNumber: e.target.value
                        }
                      }
                    }))}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <label>Zone</label>
                  <input
                    type="text"
                    name="RunningZone"
                    className="form-control"
                    placeholder="Zone"
                    value={formState?.formData?.runningPolicy?.Zone}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          Zone: e.target.value
                        }
                      }
                    }))}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Tenure</label>
                  <input
                    type="number"
                    name="RunningPolicyTenure"
                    className="form-control"
                    placeholder="Policy Tenure"
                    value={formState?.formData?.runningPolicy?.PolicyTenure}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PolicyTenure: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.RunningPolicyTenure && <div className="text-danger">{formState.errors.RunningPolicyTenure}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Premium Amount</label>
                  <input
                    type="number"
                    name="RunningPremiumAmount"
                    className="form-control"
                    placeholder="Premium Amount"
                    value={formState?.formData?.runningPolicy?.PremiumAmount}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PremiumAmount: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.RunningPremiumAmount && <div className="text-danger">{formState.errors.RunningPremiumAmount}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>From</label>
                  <input
                    type="date"
                    name="poifrom"
                    className="form-control"
                    value={formState?.formData?.runningPolicy?.PolicyFrom}
                    onChange={(e) => {
                      const poifrom = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          runningPolicy: {
                            ...prevState.formData?.runningPolicy,
                            PolicyFrom: poifrom
                          }
                        }
                      }));

                      // Validate 'From' date: Ensure it's not later than the 'To' date
                      if (new Date(poifrom) > new Date(formState.formData.runningPolicy.PolicyTo)) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poifrom: "The 'From' date cannot be later than the 'To' date."
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poifrom: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState.errors?.poifrom && <div className="text-danger">{formState.errors.poifrom}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>To</label>
                  <input
                    type="date"
                    name="poito"
                    className="form-control"
                    value={formState.formData?.runningPolicy?.PolicyTo}
                    onChange={(e) => {
                      const poito = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          runningPolicy: {
                            ...prevState.formData?.runningPolicy,
                            PolicyTo: poito,
                            ExpiryDate: poito // Automatically set Expiry Date based on "To" date
                          }
                        }
                      }));

                      // Validate 'To' date: Ensure it's not earlier than 'From' date
                      if (new Date(poito) < new Date(formState.formData.runningPolicy.PolicyFrom)) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poito: "The 'To' date cannot be earlier than the 'From' date."
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poito: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState.errors?.poito && <div className="text-danger">{formState.errors.poito}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Additional Sum Insured</label>
                  <input
                    type="number"
                    name="AdditionalSumInsured"
                    className="form-control"
                    placeholder="Additional Sum Insured"
                    value={formState?.formData?.runningPolicy?.AdditionalSumInsured}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isNaN(value) || value < 0) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            AdditionalSumInsured: "Please enter a valid positive number for Additional Sum Insured"
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            runningPolicy: {
                              ...prevState.formData?.runningPolicy,
                              AdditionalSumInsured: value
                            }
                          },
                          errors: {
                            ...prevState.errors,
                            AdditionalSumInsured: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState.errors?.AdditionalSumInsured && <div className="text-danger">{formState.errors.AdditionalSumInsured}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Add On Cover</label>
                  <input
                    type="text"
                    name="AddOnCover"
                    className="form-control"
                    placeholder="Add On Cover"
                    value={formState?.formData?.runningPolicy?.AddOnCover}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          AddOnCover: e.target.value
                        }
                      }
                    }))}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Issued Date</label>
                  <input
                    type="date"
                    name="PolicyIssuedDate"
                    className="form-control"
                    value={formState?.formData?.runningPolicy?.PolicyIssuedDate}
                    onChange={(e) => {
                      const policyIssuedDate = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          runningPolicy: {
                            ...prevState.formData?.runningPolicy,
                            PolicyIssuedDate: policyIssuedDate
                          }
                        }
                      }));

                      // Validate 'Policy Issued Date': Ensure it's not later than 'From' date
                      if (new Date(policyIssuedDate) > new Date(formState.formData.runningPolicy.PolicyFrom)) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            PolicyIssuedDate: "Policy Issued Date cannot be later than the 'From' date."
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            PolicyIssuedDate: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState.errors?.PolicyIssuedDate && <div className="text-danger">{formState.errors.PolicyIssuedDate}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Expiry Date</label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    className="form-control"
                    value={formState?.formData?.runningPolicy?.ExpiryDate}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Plan Type</label>
                  <input
                    type="text"
                    name="PolicyPlanType"
                    className="form-control"
                    placeholder="Policy Plan Type"
                    value={formState?.formData?.runningPolicy?.PolicyPlanType}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PolicyPlanType: e.target.value
                        }
                      }
                    }))}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <label>Nominee Name</label>
                  <input
                    type="text"
                    name="RunningNomineeName"
                    className="form-control"
                    placeholder="Nominee Name"
                    value={formState?.formData?.runningPolicy?.NomineeName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          NomineeName: e.target.value
                        }
                      }
                    }))}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <label>Nominee DOB</label>
                  <input
                    type="date"
                    name="RunningNomineeDob"
                    className="form-control"
                    placeholder="Nominee DOB"
                    value={formState?.formData?.runningPolicy?.NomineeDob}
                    onChange={handleNomineeDateChange}
                  />
                  {formState.errors?.RunningNomineeDob && <div className="text-danger">{formState.errors.RunningNomineeDob}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Nominee Age</label>
                  <input
                    type="text"
                    name="RunningNomineeAge"
                    className="form-control"
                    placeholder="Nominee Age"
                    value={formState?.formData?.runningPolicy?.NomineeAge}
                    readOnly
                  />
                  {formState.errors?.RunningNomineeAge && <div className="text-danger">{formState.errors.RunningNomineeAge}</div>}
                </div>
              </div>
            </>
          )}


          {currentStep === 4 && (
            <>
              <div className="mediclaim-h1">
                <h3>Previous Policy Details</h3>
              </div>
              <div className="row">
                {/* Policy Number */}
                <div className="col-md-6 mb-4">
                  <label>Policy Number</label>
                  <input
                    type="text"
                    name="PolicyNumber"
                    className="form-control"
                    placeholder="Policy Number"
                    value={formState?.formData?.previousPolicy?.PolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PolicyNumber: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.PolicyNumber && <div className="text-danger">{formState.errors.PolicyNumber}</div>}
                </div>

                {/* Upload PDF for the policy */}
                <div className="col-md-6 mb-4">
                  <label>Upload PDF</label>
                  <input
                    type="file"
                    name="PDFfile"
                    className="form-control"
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PDFfile: e.target.files[0]
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.PDFfile && <div className="text-danger">{formState.errors.PDFfile}</div>}
                </div>

                
                {/* From Date */}
                <div className="col-md-6 mb-4">
                  <label>From</label>
                  <input
                    type="date"
                    name="poifrom"
                    className="form-control"
                    value={formState.formData?.previousPolicy?.PolicyFrom}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PolicyFrom: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.poifrom && <div className="text-danger">{formState.errors.poifrom}</div>}
                </div>

                {/* To Date */}
                <div className="col-md-6 mb-4">
                  <label>To</label>
                  <input
                    type="date"
                    name="poito"
                    className="form-control"
                    value={formState.formData?.previousPolicy?.PolicyTo}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PolicyTo: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.poito && <div className="text-danger">{formState.errors.poito}</div>}
                </div>

                {/* Zone */}
                <div className="col-md-6 mb-4">
                  <label>Zone</label>
                  <input
                    type="text"
                    name="Zone"
                    className="form-control"
                    placeholder="Zone"
                    value={formState.formData?.previousPolicy?.Zone}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          Zone: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.Zone && <div className="text-danger">{formState.errors.Zone}</div>}
                </div>

                {/* Policy Tenure */}
                <div className="col-md-6 mb-4">
                  <label>Policy Tenure</label>
                  <input
                    type="number"
                    name="PolicyTenure"
                    className="form-control"
                    placeholder="Policy Tenure"
                    value={formState.formData?.previousPolicy?.PolicyTenure}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData.previousPolicy,
                          PolicyTenure: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.PolicyTenure && <div className="text-danger">{formState.errors.PolicyTenure}</div>}
                </div>

                {/* Premium Amount */}
                <div className="col-md-6 mb-4">
                  <label>Premium Amount</label>
                  <input
                    type="number"
                    name="PremiumAmount"
                    className="form-control"
                    placeholder="Premium Amount"
                    value={formState?.formData?.previousPolicy?.PremiumAmount}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PremiumAmount: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.PremiumAmount && <div className="text-danger">{formState.errors.PremiumAmount}</div>}
                </div>

                {/* Renew Date */}
                <div className="col-md-6 mb-4">
                  <label>Renew Date</label>
                  <input
                    type="date"
                    name="RenewDate"
                    className="form-control"
                    value={formState.formData?.previousPolicy?.RenewDate}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          RenewDate: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.RenewDate && <div className="text-danger">{formState.errors.RenewDate}</div>}
                </div>

                {/* Claim Expire in Policy (Yes/No) */}
                <div className="col-md-6 mb-4">
                  <label>Claim Expire in Policy</label>
                  <select
                    name="ClaimExpireInPolicy"
                    className="form-select"
                    value={formState.formData?.previousPolicy?.ClaimExpireInPolicy}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          ClaimExpireInPolicy: e.target.value
                        }
                      }
                    }))}
                  >
                    <option value="" disabled selected>Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {formState?.errors?.ClaimExpireInPolicy && <div className="text-danger">{formState.errors.ClaimExpireInPolicy}</div>}
                </div>

                {/* Upload Claim Statement PDF */}
                <div className="col-md-6 mb-4">
                  <label>Claim Statement PDF</label>
                  <input
                    type="file"
                    name="ClaimStatementPDF"
                    className="form-control"
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          ClaimStatementPDF: e.target.files[0]
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.ClaimStatementPDF && <div className="text-danger">{formState.errors.ClaimStatementPDF}</div>}
                </div>

                {/* Nominee Name */}
                <div className="col-md-6 mb-4">
                  <label>Nominee Name</label>
                  <input
                    type="text"
                    name="NomineeName"
                    className="form-control"
                    placeholder="Nominee Name"
                    value={formState.formData?.previousPolicy?.NomineeName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          NomineeName: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.NomineeName && <div className="text-danger">{formState.errors.NomineeName}</div>}
                </div>

                {/* Previous Policy Number */}
                <div className="col-md-6 mb-4">
                  <label>Previous Policy Number</label>
                  <input
                    type="text"
                    name="PreviousPolicyNumber"
                    className="form-control"
                    placeholder="Previous Policy Number"
                    value={formState.formData?.previousPolicy?.PreviousPolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PreviousPolicyNumber: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState?.errors?.PreviousPolicyNumber && <div className="text-danger">{formState.errors.PreviousPolicyNumber}</div>}
                </div>

                {/* Age */}
                <div className="col-md-6 mb-4">
                  <label>Age</label>
                  <input
                    type="number"
                    name="Age"
                    className="form-control"
                    placeholder="Age"
                    value={formState.formData.Age}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Age: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Age && <div className="text-danger">{formState.errors.Age}</div>}
                </div>

                {/* Product Selection */}
                <div className="col-md-6 mb-4">
                  <label>Select Product</label>
                  <select
                    name="ProductName"
                    className="form-select"
                    value={formState.formData.ProductName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        ProductName: e.target.value
                      }
                    }))}
                  >
                    <option value="" disabled selected hidden>Select Product</option>
                    {productData && productData.map((item) => (
                      <option key={item.mediclaim_product_id} value={item.mediclaim_product_id}>
                        {item.mediclaim_product_name}
                      </option>
                    ))}
                  </select>
                  {formState?.errors?.ProductName && <div className="text-danger">{formState.errors.ProductName}</div>}
                </div>

                {/* Company Name */}
                <div className="col-md-6 mb-4">
                  <label>Company Name</label>
                  <input
                    type="text"
                    name="CompanyName"
                    className="form-control"
                    placeholder="Company Name"
                    value={formState.formData.CompanyName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        CompanyName: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.CompanyName && <div className="text-danger">{formState.errors.CompanyName}</div>}
                </div>
              </div>
            </>
          )}


          {currentStep === 5 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Agent Details</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="AgentName"
                    className='form-control'
                    placeholder="Agent Name"
                    value={formState.formData.AgentName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentName: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentName && <div className="text-danger">{formState.errors.AgentName}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="AgentContactNumber"
                    className='form-control'
                    placeholder="Agent Contact Number"
                    value={formState.formData.AgentContactNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentContactNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentContactNumber && <div className="text-danger">{formState.errors.AgentContactNumber}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="AgentCode"
                    className='form-control'
                    placeholder="Agent Code"
                    value={formState.formData.AgentCode}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentCode: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentCode && <div className="text-danger">{formState.errors.AgentCode}</div>}
                </div>
              </div>
            </>
          )}
          <div className="button-group d-flex justify-content-between">
            {currentStep > 1 && (
              <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handlePrev(); }}>Back</button>
            )}
            {currentStep < 5 ? (
              <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handleNext(); }}>Next</button>
            ) : (
              !view && currentStep === 5 && (
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              )
            )}
          </div>
        </form>
      </div>
    </div>
  );
};


export default MediclaimPopup;
